import classNames from 'classnames'

import './Button.css'

export default function Button({ children, small = false, primary = false, secondary = false }) {
  const btnClass = classNames({
    Button: true,
    'Button-primary': primary,
    'Button-small': small,
    'Button-secondary': secondary,
  });

  return (
    <div className={btnClass}>
      {children}
    </div>
  )
}
