import { useTranslation } from 'react-i18next'

import './Submission.css'
import Button from '../../components/Button'

function Retry({ kind }) {
  const { t } = useTranslation('translations')

  return (
    <div className="Submission">
      <div className="Submission__content">
        <p className="Submission__description">{t(`${kind}.description`)}</p>
        <div className="Submission__cta">
          <Button primary>
            <a href="https://www.dior.com">
              {t(`${kind}.visit`)}
            </a>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Retry
