import { useRef, useEffect, useMemo } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { EffectComposer, Vignette, SMAA, DepthOfField } from '@react-three/postprocessing'
import { lerp } from 'three/src/math/MathUtils'

import { useGame } from '../../hooks'

let focusDistance = -6
let bokehScale = 5

export default function Effects() {
  const dofRef = useRef(null)
  const { playing } = useGame()
  const { size } = useThree()

  useEffect(() => {
    focusDistance = (playing ? 5.1 : 0) * 0.001
    bokehScale = playing ? 1.7 : 5
  }, [playing])

  useFrame(() => {
    if (dofRef.current) {
      // if (dofRef.current.bokehScale === bokehScale) return
      dofRef.current.bokehScale = lerp(dofRef.current.bokehScale, bokehScale, 0.065)
      // if (dofRef.current.circleOfConfusionMaterial.uniforms.focusDistance.value === focusDistance) return
      dofRef.current.circleOfConfusionMaterial.uniforms.focusDistance.value = lerp(
        dofRef.current.circleOfConfusionMaterial.uniforms.focusDistance.value,
        focusDistance,
        0.065
      )
    }
  })

  return useMemo(() => (
    <EffectComposer
      disableNormalPass
      autoClear={false}
      multisampling={0}
    >
      <DepthOfField
        ref={dofRef}
        focusDistance={0.0051}
        focalLength={0.0073}
        bokehScale={5}
        width={size.width / 2}
        height={size.height / 2}
      />
      <Vignette
        eskil={false}
        offset={0.04}
        darkness={0.9}
      />
      <SMAA />
    </EffectComposer>
  ), [size])
}
