import { useMemo } from 'react'
import { Vector3 } from 'three/src/math/Vector3'
import { CatmullRomCurve3 } from 'three/src/extras/curves/CatmullRomCurve3'

export default function useTapeCurve() {
  const handlePos = useMemo(
    () => [
      [-113.25435638427734, 10.339855194091797, 0.0],
      [-111.89570617675781, 2.337996482849121, 0.0],
      [-109.70059967041016, 0.318373441696167, 0.0],
      [-106.56668090820312, 0.009657954797148705, 0.0],
      [-102.61433410644531, -0.02131900005042553, 0.0],
      [-97.83465576171875, -0.0252052154392004, 0.0],
      [-92.63663482666016, -0.027697529643774033, 0.0],
      [-87.32539367675781, -0.030300062149763107, 0.0],
      [-81.99633026123047, -0.03284178301692009, 0.0],
      [-76.66607666015625, -0.03518318757414818, 0.0],
      [-71.33537292480469, -0.03765397518873215, 0.0],
      [-66.003662109375, -0.040498778223991394, 0.0],
      [-60.67259216308594, -0.043101947754621506, 0.0],
      [-55.34336853027344, -0.04500709846615791, 0.0],
      [-50.0129508972168, -0.0473589226603508, 0.0],
      [-44.67909240722656, -0.05101644992828369, 0.0],
      [-39.34746551513672, -0.05388079956173897, 0.0],
      [-34.022247314453125, -0.05428844690322876, 0.0],
      [-28.692928314208984, -0.055839285254478455, 0.0],
      [-23.351604461669922, -0.06213713437318802, 0.0],
      [-18.01761245727539, -0.06920401751995087, 0.0],
      [-12.706789016723633, -0.0651761069893837, 0.0],
      [-7.39044189453125, -0.009288161993026733, 0.0],
      [-2.0584073066711426, 0.23158840835094452, 0.0],
      [3.1850218772888184, 0.9845454692840576, 0.0],
      [8.174022674560547, 2.683562755584717, 0.0],
      [12.774526596069336, 5.2459716796875, 0.0],
      [16.757625579833984, 8.696901321411133, 0.0],
      [20.058603286743164, 12.807172775268555, 0.0],
      [22.4615478515625, 17.494720458984375, 0.0],
      [23.98698616027832, 22.535459518432617, 0.0],
      [24.46051788330078, 27.784412384033203, 0.0],
      [23.986988067626953, 33.03335952758789, 0.0],
      [22.461557388305664, 38.074100494384766, 0.0],
      [20.05860710144043, 42.76164627075195, 0.0],
      [16.75762939453125, 46.871910095214844, 0.0],
      [12.774528503417969, 50.322837829589844, 0.0],
      [8.174022674560547, 52.88524627685547, 0.0],
      [3.1850223541259766, 54.58427047729492, 0.0],
      [-2.0584073066711426, 55.33722686767578, 0.0],
      [-7.390442371368408, 55.57809829711914, 0.0],
      [-12.706789016723633, 55.63398742675781, 0.0],
      [-18.01761245727539, 55.63800811767578, 0.0],
      [-23.35160255432129, 55.630943298339844, 0.0],
      [-28.692928314208984, 55.62464904785156, 0.0],
      [-34.022247314453125, 55.62310028076172, 0.0],
      [-39.34746551513672, 55.62268829345703, 0.0],
      [-44.67909240722656, 55.61982727050781, 0.0],
      [-50.0129508972168, 55.61617660522461, 0.0],
      [-55.34336853027344, 55.61381530761719, 0.0],
      [-60.67259216308594, 55.61191177368164, 0.0],
      [-66.003662109375, 55.60930633544922, 0.0],
      [-71.33537292480469, 55.60646057128906, 0.0],
      [-76.66607666015625, 55.6039924621582, 0.0],
      [-81.99633026123047, 55.601654052734375, 0.0],
      [-87.32539367675781, 55.59910583496094, 0.0],
      [-92.63663482666016, 55.59650421142578, 0.0],
      [-97.83465576171875, 55.59401321411133, 0.0],
      [-102.61433410644531, 55.59012985229492, 0.0],
      [-106.56668090820312, 55.55915069580078, 0.0],
      [-109.70059967041016, 55.25043487548828, 0.0],
      [-111.89570617675781, 53.230812072753906, 0.0],
      [-113.25435638427734, 45.22895431518555, 0.0],
      [-113.67257690429688, 27.784404754638672, 0.0],
    ].map((hand, i) => {
        const x = hand[0] * 0.1  -0.35430601239204407
        const y = hand[1] * 0.1  +1.0169399976730347
        const z = hand[2] * 0.1  -0.5204780101776123
        return new Vector3(x, z, -y)
      }),
    []
  )

  const curve = useMemo(() => new CatmullRomCurve3(handlePos, true, 'centripetal'), [handlePos])

  return curve
}
