/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF, useTexture } from '@react-three/drei'
import { animated } from '@react-spring/three'
import { BufferAttribute } from 'three/src/core/BufferAttribute'

import {
    useGame,
    useTheme,
    useColorAnimation,
    useFadeInAnimation,
    useTapeCurve
} from '../../hooks'

import CurveModifier from '../CurveModifier'

export default function Tape({ ...props }) {
  const group = useRef()
  const curveRef = useRef()
  const cheatCopyRef = useRef()

  const playing = useGame(state => state.playing)
  const paused = useGame(state => state.paused)
  const current = useTheme(state => state.current)
  // const completed = useGame(state => state.completed)

  // const { nodes, materials } = useGLTF('/models/Dior_Rouge_Tape_4.gltf')
  const { nodes, materials } = useGLTF('/models/Dior_Rouge_Tape_11.gltf')

  const [bumpTexture, aoTexture] = useTexture([
    '/textures/bumps/tape2.png',
    '/textures/ambientOcclusions/tape4.jpg',
  ])

  const color = useColorAnimation()
  const alpha = useFadeInAnimation()

  const curve = useTapeCurve()

  useEffect(() => {
    const attributes = new BufferAttribute(nodes.Tape_mdl.geometry.attributes.uv.array, 2)
    nodes.Tape_mdl.geometry.setAttribute('uv2', attributes)

    nodes.Tape_mdl.geometry.rotateX(Math.PI / 2)
    nodes.Tape_mdl.geometry.scale(0.1, 0.1, 0.1)
  }, [nodes, materials])

  useFrame(({ clock }) => {
    // aoTexture.offset.y = clock.getElapsedTime() * speed
    if (curveRef.current && curveRef.current.object3D) {
      !paused && playing && curveRef.current?.moveAlongCurve(0.0005 + (current * 0.000025))

      if (curveRef.current.object3D.material.opacity !== cheatCopyRef.current?.material.opacity) {
        curveRef.current.object3D.material.opacity = cheatCopyRef.current?.material.opacity
      }

      if (curveRef.current.object3D.material.color !== cheatCopyRef.current?.material.color) {
        curveRef.current.object3D.material.color = cheatCopyRef.current?.material.color
      }
    }
  })

  return (
    <group ref={group} {...props} dispose={null} renderOrder={0}>
      <CurveModifier ref={curveRef} curve={curve}>
        <mesh geometry={nodes.Tape_mdl.geometry}>
          <meshStandardMaterial
            transparent
            attach="material"
            color={color}
            aoMap={aoTexture}
            bumpMap={bumpTexture}
            aoMap-flipY={true}
            bumpMap-flipY={true}
          />
        </mesh>
      </CurveModifier>
      <mesh visible={false} ref={cheatCopyRef} geometry={nodes.Tape_mdl.geometry}>
        <animated.meshStandardMaterial
          transparent
          opacity={alpha}
          attach="material"
          color={color}
          aoMap={aoTexture}
          bumpMap={bumpTexture}
          aoMap-flipY={true}
          bumpMap-flipY={true}
        />
      </mesh>
    </group>

    // <group ref={group} {...props} dispose={null}>
    //   <mesh
    //     geometry={nodes.Tape_mdl.geometry}
    //     position={[-0.35, -0.52, -1.02]}
    //     rotation={[Math.PI / 2, 0, 0]}
    //     scale={0.1}
    //   >
    //     <animated.meshStandardMaterial
    //       transparent={true}
    //       alphaTest={0.5}
    //       // side={DoubleSide}
    //       attach="material"
    //       color={color}
    //       aoMap={aoTexture}
    //       bumpMap={bumpTexture}
    //       aoMap-flipY={true}
    //       aoMap-wrapT={RepeatWrapping}
    //       aoMap-magFilter={NearestFilter}
    //       aoMap-repeatY={1}
    //       bumpMap-flipY={true}
    //     />
    //   </mesh>
    // </group>
  )
}

useGLTF.preload('/models/Dior_Rouge_Tape_11.gltf')
