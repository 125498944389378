import { Loader } from 'resource-loader'
import create from 'zustand';

const loader = new Loader()

loader
  .add('/models/Dior_Rouge_lipstick_simple4.gltf')
  .add('/models/Dior_Rouge_Enviroment.gltf')
  .add('/models/Dior_Rouge_lipstick_3.gltf')
  .add('/models/Dior_Rouge_Tape_11.gltf')
  .add('/textures/normals/Lipstic_Normal.png')
  .add('/textures/normals/Velvet_Normal.jpg')
  .add('/textures/matcaps/Base_Inside.png')
  .add('/textures/matcaps/Cap_Red.png')
  .add('/textures/matcaps/Metal_Base.png')
  .add('/textures/matcaps/Mid_night_black.png')
  .add('/textures/matcaps/Metal_Refil_2.png')
  .add('/textures/matcaps/Color_Velvet.png')
  .add('/textures/matcaps/999_Satin.png')
  .add('/textures/matcaps/100_Mat.png')
  .add('/textures/baseColors/720_Velvet_Base_color.png')
  .add('/textures/baseColors/999_Velvet_Base_color.png')
  .add('/textures/matcaps/772_Mat.png')
  .add('/textures/matcaps/999_Mat.png')
  .add('/textures/matcaps/219_Satin.png')
  .add('/textures/matcaps/434_Satin.png')
  .add('/textures/baseColors/999_Metallic_BaseColor.png')
  .add('/textures/matcaps/000_Balm_Satin.png')
  .add('/textures/bumps/tape2.png')
  .add('/textures/ambientOcclusions/tape4.png')
  .add('/textures/ambientOcclusions/roller2.jpg')

const useProgress = create(set => {

  loader.onStart.add((item, loaded, total) => {
    // console.log('onstart')
    set({
      active: true,
      // item,
      // loaded,
      // total,
      // progress: (loaded - saveLastTotalLoaded) / (total - saveLastTotalLoaded) * 100
    });
  })

  loader.onComplete.add((resources) => {
    // console.log('onend', loader, resources)
    set({
      active: false
    });
  })

  // loader.onError.add(item => set(state => ({
  //   errors: [...state.errors, item]
  // }));

  loader.onProgress.add((item, loaded, total) => {
    // if (loaded === total) {
    //   saveLastTotalLoaded = total;
    // }

    // set({
    //   active: true,
    //   item,
    //   loaded,
    //   total,
    //   progress: (loaded - saveLastTotalLoaded) / (total - saveLastTotalLoaded) * 100 || 100
    // });
  })

  loader.load((loader, resources) => {
    // console.log('res', resources)
    set({
      resources
    })
  })

  return {
    // errors: [],
    active: true,
    // progress: 0,
    // item: '',
    // loaded: 0,
    // total: 0
    resources: null,
  };
});

export default useProgress
