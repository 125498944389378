import { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import useGame from './useGame'
import useTheme from './useTheme'

export default function useGameChecker() {
  const navigate = useNavigate()

  const score = useGame(state => state.score)
  const completed = useGame(state => state.completed)
  const pause = useGame(state => state.pause)
  const unpause = useGame(state => state.unpause)
  const maxScore = useTheme(state => state.colors.length * 10)

  useEffect(() => {
    if (completed) {
      const route = score === maxScore ? 'win' : 'retry'
      navigate(`/${route}`, { replace: true })
    }
  }, [completed])

  const onBlur = useCallback(() => {
    pause()
  }, [])

  const onFocus = useCallback(() => {
    unpause()
  }, [])

  useEffect(() => {
    window.addEventListener('blur', onBlur)
    window.addEventListener('focus', onFocus)
  }, [])
}
