import create from 'zustand'

const useGame = create((set, get) => ({
  score: 0,
  playing: false,
  completed: false,
  played: false,
  paused: false,
  start: () => set(() => ({ playing: true, played: true })),
  stop: () => set(() => ({ playing: false })),
  pause: () => set(() => ({ paused: true })),
  unpause: () => set(() => ({ paused: false })),
  complete: () => set(() => ({ completed: true, playing: false })),
  reset: () => set(() => ({ completed: false, score: 0 })),
  right: (score) => set(state => ({ score: score + 10 })),
  error: (score) => set(state => ({ score: score - 5 })),
}))

export default useGame
