import { useEffect, useMemo, useState } from 'react'
import {
  animated,
  config,
  useSpring,
  useSpringRef,
  useChain
} from 'react-spring'

import './Score.css'
import scorePng from './score.png'

import { useGame } from '../../hooks'

function Counter({ value }) {
  const playing = useGame(state => state.playing)
  const completed = useGame(state => state.completed)

  const render = useMemo(() => {
    return playing || completed
  }, [playing, completed])

  const scaleRef = useSpringRef()
  const scaleSpring = useSpring({
    from: { scale: 1.3, opacity: 0 },
    to: { scale: 1, opacity: 1 },
    config: config.gentle,
    // delay: 1,
    // reverse,
    ref: scaleRef,
    reset: true,
  })

  const animationRef = useSpringRef()
  const animationSpring = useSpring({
    from: { opacity: 0, transform: `translate3d(0, 100%, 0)` },
    to: { opacity: 1, transform: `translate3d(0, 0, 0)` },
    config: config.stiff,
    // delay: 1.1,
    // reverse,
    // onRest: () => set(true),
    ref: animationRef,
    reset: true,
  })

  const rotateRef = useSpringRef()
  const rotateSpring = useSpring({
    from: { transform: `rotate(0deg)` },
    to: { transform: `rotate(90deg)` },
    config: config.stiff,
    // delay: 1.2,
    // reverse,
    // onRest: () => set(true),
    ref: rotateRef,
    reset: true,
  })

  useChain([
    scaleRef,
    rotateRef,
    animationRef
  ], [
    0,
    0.1,
    0,
  ])

  // scaleRef.start(value => ({ delay: 1, from: { scale: 1.3, opacity: 0 }, to: { scale: 1, opacity: 1 }, }))
  // rotateRef.start(value => ({ delay: 1.1, from: { transform: `rotate(0deg)` }, to: { transform: `rotate(90deg)` } }))
  // animationRef.start(value => ({ delay: 1.2, from: { opacity: 0, transform: `translate3d(0, 100%, 0)` }, to: { opacity: 1, transform: `translate3d(0, 0, 0)` }, }))

  return render && (
    <animated.div style={scaleSpring} className="Score">
      <animated.img
        className="Score__circle"
        src={scorePng}
        alt="score"
        style={rotateSpring}
      />
      <div className="Score__value">
        <div className="Score__mask">
          <animated.div style={animationSpring}>
            { value }
          </animated.div>
        </div>
      </div>
    </animated.div>
  )
}

export default function Score() {
  const [value, set] = useState(0)
  const score = useGame(state => state.score)

  useEffect(() => {
    if (score === 0) {
      set(score)
    } else {
      setTimeout(() => set(score), 1500)
    }
  }, [score])

  return useMemo(() => (
    <Counter
      value={value}
    />
  ), [value])
}

