import { useTranslation } from 'react-i18next'

import './DesktopLayer.css'
import QR from './QR.svg'

import Logo from '../../components/Logo'
import LangSwitcher from '../../components/LangSwitcher'

function DesktopLayer() {
  const { t } = useTranslation('translations')

  return (
    <div className="DesktopLayer">
      <LangSwitcher />
      <div className="DesktopLayer__bar">
        <span>
          <Logo />
        </span>
      </div>
      <div className="DesktopLayer__grid">
        <div className="DesktopLayer__image">
      </div>
        <div className="DesktopLayer__text">
        <svg className="DesktopLayer__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1106.64 112">
          <path d="M520.14 592.75h-29.22a286.92 286.92 0 0 1-18.91-23 186.73 186.73 0 0 1-15.5-24.91 33.12 33.12 0 0 1-4.14-.33c-1.68-.21-3.41-.45-5.19-.73s-3.52-.59-5.19-1-3.06-.73-4.14-1.05v25.32q0 6.81.4 11.28a16.61 16.61 0 0 0 2.11 7.22 10 10 0 0 0 5.11 4.06 35.57 35.57 0 0 0 9.42 1.78v1.95q-6.17-.33-12.34-.49t-12.33-.16q-6 0-11.85.16t-11.69.49v-1.95a32.7 32.7 0 0 0 9.09-1.78 10 10 0 0 0 5-4.06 16.48 16.48 0 0 0 2.11-7.22q.41-4.47.41-11.28v-54.16q0-6.81-.41-11.28a16.48 16.48 0 0 0-2.11-7.22 10 10 0 0 0-5-4.06 32.7 32.7 0 0 0-9.09-1.78v-1.95a328.24 328.24 0 0 0 20.78.65c1.51 0 3.41-.08 5.68-.24l7.22-.57q3.81-.33 7.71-.57t7-.24a60.11 60.11 0 0 1 14.29 1.78 40.89 40.89 0 0 1 13.14 5.68 32.21 32.21 0 0 1 9.58 9.9 26.53 26.53 0 0 1 3.73 14.29 23.57 23.57 0 0 1-2 10.06 24.9 24.9 0 0 1-5.43 7.63 28.94 28.94 0 0 1-8 5.35 42.53 42.53 0 0 1-9.66 3.09q2.28 3.9 5.36 8.76t6.9 9.91q3.81 5 8.11 10a78.69 78.69 0 0 0 9.09 8.92 58.07 58.07 0 0 0 9.82 6.66 27 27 0 0 0 10.23 3.16Zm-82.29-56.48a42.35 42.35 0 0 0 15.09 2.73q12.66 0 19.64-5.85t7-19a22.42 22.42 0 0 0-2-9.66 23 23 0 0 0-5.6-7.46 25.62 25.62 0 0 0-8.19-4.87 27.69 27.69 0 0 0-9.66-1.71q-4.06 0-8 .33a36.52 36.52 0 0 0-7.62 1.46q-.33 7.79-.49 15.74t-.16 17.21Z" transform="translate(-406.68 -484)"/>
          <path d="M525.17 540a55.5 55.5 0 0 1 4.83-23.54 53 53 0 0 1 12.9-17.61 56.1 56.1 0 0 1 19.07-11 72.94 72.94 0 0 1 46.59 0 56.1 56.1 0 0 1 19.07 11 53 53 0 0 1 12.9 17.61 60.21 60.21 0 0 1 0 47.07 52.89 52.89 0 0 1-12.9 17.62 56.1 56.1 0 0 1-19.07 11 72.94 72.94 0 0 1-46.59 0 56.1 56.1 0 0 1-19.07-11 52.89 52.89 0 0 1-12.9-17.62 55.46 55.46 0 0 1-4.83-23.53Zm17.2 0a61.61 61.61 0 0 0 2.93 19 49.48 49.48 0 0 0 8.44 16 41.52 41.52 0 0 0 13.47 11 41.36 41.36 0 0 0 36 0 41.73 41.73 0 0 0 13.47-11 49.67 49.67 0 0 0 8.44-16 63.21 63.21 0 0 0 0-38 49.67 49.67 0 0 0-8.44-16 41.73 41.73 0 0 0-13.47-11 41.36 41.36 0 0 0-36 0 41.52 41.52 0 0 0-13.47 11 49.48 49.48 0 0 0-8.44 16 61.61 61.61 0 0 0-2.93 19Z" transform="translate(-406.68 -484)"/>
          <path d="M673.52 503a22.13 22.13 0 0 0-.89-7.06 8.84 8.84 0 0 0-2.68-4 10.92 10.92 0 0 0-4.38-2.11c-1.73-.44-3.74-.87-6-1.3v-1.93q6.83.33 13.39.49t13.39.16q4.88 0 9.66-.16t9.66-.49v1.95a38.78 38.78 0 0 0-8.6 1.62 12.47 12.47 0 0 0-5.36 3.25 12 12 0 0 0-2.76 5.43 35.28 35.28 0 0 0-.81 8.2V560a30 30 0 0 0 2.51 12.91 23.08 23.08 0 0 0 6.9 8.6 29 29 0 0 0 10.15 4.87 47.1 47.1 0 0 0 12.25 1.54q10.39 0 17-3.08a31 31 0 0 0 10.54-7.55 24.82 24.82 0 0 0 5.36-9.58 33 33 0 0 0 1.46-9v-45.49a88.65 88.65 0 0 0-.57-11 18.7 18.7 0 0 0-2.27-7.3 10.82 10.82 0 0 0-4.79-4.3 26.79 26.79 0 0 0-8-2.11v-1.91q5 .33 10 .49t10 .16q4.23 0 8.28-.16t8.28-.49v1.95a44.89 44.89 0 0 0-6.25 1.3 10.19 10.19 0 0 0-4.79 2.92 14.12 14.12 0 0 0-3 6.08 43.6 43.6 0 0 0-1 10.8v48.53a37.65 37.65 0 0 1-3.49 16.8 33.88 33.88 0 0 1-9.5 11.77 40.22 40.22 0 0 1-14 7 62.84 62.84 0 0 1-17.16 2.25 57.35 57.35 0 0 1-15.91-2.19 41 41 0 0 1-13.55-6.66 33.59 33.59 0 0 1-9.5-11.12 32.09 32.09 0 0 1-3.57-15.42Z" transform="translate(-406.68 -484)"/>
          <path d="M886.47 521.33q-3.08-6.17-6.57-12a53.77 53.77 0 0 0-8-10.39 36.62 36.62 0 0 0-10.47-7.3 32.54 32.54 0 0 0-13.87-2.76 30.31 30.31 0 0 0-16.56 4.54 42.87 42.87 0 0 0-12.33 11.85 55.6 55.6 0 0 0-7.79 16.39 63.89 63.89 0 0 0-2.68 18.18 68.67 68.67 0 0 0 2.68 19.39 49.24 49.24 0 0 0 7.79 16A39.52 39.52 0 0 0 831 586.1a33.13 33.13 0 0 0 16.48 4 75 75 0 0 0 15.25-1.54 36 36 0 0 0 14-6.25v-20.88q0-11-16.71-11.53V548c4.11.11 7.89.25 11.36.41s7.35.24 11.68.24q6 0 12-.16t12-.49v1.95a37.67 37.67 0 0 0-6.33.81 13.46 13.46 0 0 0-5 2.11 10.29 10.29 0 0 0-3.25 4 14.74 14.74 0 0 0-1.22 6.41V586q-11.19 3.74-22.15 6.9A76.29 76.29 0 0 1 848 596a63.75 63.75 0 0 1-21.26-3.65 56.61 56.61 0 0 1-18.58-10.8A53.25 53.25 0 0 1 795 564q-4.95-10.38-5-24a50.56 50.56 0 0 1 5-22.16 57.32 57.32 0 0 1 13.15-17.77 63.45 63.45 0 0 1 18.58-11.77A55.2 55.2 0 0 1 848 484a114.6 114.6 0 0 1 12 .57q5.36.57 9.73 1.46t8 1.87q3.57 1 6.81 1.78 0 2.61.41 6.9c.27 2.87.62 5.84 1.06 8.93s.86 6.06 1.29 8.93.82 5.16 1.14 6.89Z" transform="translate(-406.68 -484)"/>
          <path d="M945.55 586.42c1 .22 2.17.44 3.57.65s2.93.43 4.55.65 3.3.41 5 .57 3.35.24 4.87.24a85.29 85.29 0 0 0 17.46-1.62 49.71 49.71 0 0 0 14.12-5.2 57.33 57.33 0 0 0 12.09-9.09 113 113 0 0 0 11.53-13.3l1.78 1.46-14.77 32.62q-2.76-.33-5.68-.49c-2-.1-3.84-.16-5.68-.16h-61c-3.14 0-6.28.06-9.41.16s-6.28.27-9.42.49v-1.95a32.7 32.7 0 0 0 9.09-1.78 9.59 9.59 0 0 0 4.87-4.06 17.35 17.35 0 0 0 2-7.22q.4-4.47.41-11.28v-54.22q0-6.81-.41-11.28a17.35 17.35 0 0 0-2-7.22 9.54 9.54 0 0 0-5-4.06 35 35 0 0 0-9.25-1.78v-1.95l10.47.32c3.3.11 6.7.19 10.22.25s7.25.08 11.2.08h13.39q11.36 0 22.72-.16t23.06-.49c-.11 1.19-.2 2.38-.25 3.57s-.08 2.33-.08 3.41v6.49c0 2.38.1 4.79.33 7.22s.42 5.12.64 8l-2.11.65a40 40 0 0 0-5.6-12.74 26.66 26.66 0 0 0-8.11-7.71 29.7 29.7 0 0 0-10.15-3.7 67.09 67.09 0 0 0-11.68-1q-11.37 0-22.73 1v40.58q3.26 0 6.25-.16c2-.11 4.09-.16 6.25-.16q11.21 0 17.77-4.47t8-16.31h1.95c0 3.9-.06 7.79-.16 11.69s-.17 7.73-.17 11.52.06 7.79.17 11.69.16 7.79.16 11.68h-1.95a49.4 49.4 0 0 0-3.57-11.11 17.27 17.27 0 0 0-5.28-6.58 18.81 18.81 0 0 0-7.87-3.08 67 67 0 0 0-11.2-.81h-10.39Z" transform="translate(-406.68 -484)"/>
          <path d="M1066.31 591.45a30.29 30.29 0 0 0 8.53-1.78 14.79 14.79 0 0 0 4.87-3 7.94 7.94 0 0 0 2.19-3.65 16.18 16.18 0 0 0 .48-3.89v-75a42.33 42.33 0 0 0-.4-6.25 9.25 9.25 0 0 0-2-4.63 10.92 10.92 0 0 0-4.87-3.08 37.11 37.11 0 0 0-8.77-1.62v-1.95q4.23.33 8.44.49t8.44.16a182.22 182.22 0 0 0 21.11-1.14 173.72 173.72 0 0 1 20-1.13 73.88 73.88 0 0 1 24.67 4 61.86 61.86 0 0 1 20.21 11.61 54.61 54.61 0 0 1 13.71 18.26 55.74 55.74 0 0 1 5 24q0 13.47-4.87 23a44.64 44.64 0 0 1-13 15.58 53.18 53.18 0 0 1-18.58 8.85 85.31 85.31 0 0 1-21.83 2.76q-8.77 0-15-.09c-4.16 0-7.82-.08-10.95-.08s-6 0-8.44-.08-4.87-.08-7.15-.08c-3.68 0-7.3.06-10.87.16s-7.2.27-10.88.49Zm30.68-11a29.06 29.06 0 0 0 11.93 6.81 48.52 48.52 0 0 0 13.55 1.95q25 0 36.44-12.82t11.45-37.17a50.89 50.89 0 0 0-3.82-19.72 49.55 49.55 0 0 0-10.55-16 48.47 48.47 0 0 0-16.07-10.71 54.28 54.28 0 0 0-42 .65q-.64 8.43-.81 16.8t-.16 17.12Z" transform="translate(-406.68 -484)"/>
          <path d="M1202.82 591.45a32.86 32.86 0 0 0 9.09-1.78 10 10 0 0 0 4.95-4.06 16.61 16.61 0 0 0 2.11-7.22c.26-3 .4-6.74.4-11.28v-54.22c0-4.54-.14-8.3-.4-11.28a16.61 16.61 0 0 0-2.11-7.22 10 10 0 0 0-5.12-4.06 34.94 34.94 0 0 0-9.25-1.78v-1.95c4.55.22 9 .38 13.23.49s8.68.16 13.23.16q5.35 0 10.71-.16t10.55-.49v1.95a32.33 32.33 0 0 0-8.93 1.78 9.57 9.57 0 0 0-4.86 4.06 17.2 17.2 0 0 0-2 7.22c-.28 3-.41 6.74-.41 11.28v54.22c0 4.54.13 8.3.41 11.28a16.48 16.48 0 0 0 2.11 7.22 9.93 9.93 0 0 0 5.11 4.06 35.48 35.48 0 0 0 9.41 1.78v1.95q-6.16-.33-12.33-.49t-12.34-.16q-6 0-11.85.16t-11.68.49Z" transform="translate(-406.68 -484)"/>
          <path d="M1266 540a55.66 55.66 0 0 1 4.79-23.54 52.84 52.84 0 0 1 12.91-17.61 56 56 0 0 1 19.07-11 72.91 72.91 0 0 1 46.58 0 56 56 0 0 1 19.07 11 53.11 53.11 0 0 1 12.91 17.61 60.21 60.21 0 0 1 0 47.07 53.05 53.05 0 0 1-12.91 17.62 56 56 0 0 1-19.07 11 72.91 72.91 0 0 1-46.58 0 56 56 0 0 1-19.07-11 52.78 52.78 0 0 1-12.91-17.62A55.61 55.61 0 0 1 1266 540Zm17.21 0a61.61 61.61 0 0 0 2.92 19 49.67 49.67 0 0 0 8.44 16 41.73 41.73 0 0 0 13.43 11 41.38 41.38 0 0 0 36 0 41.73 41.73 0 0 0 13.47-11 49.67 49.67 0 0 0 8.44-16 63.21 63.21 0 0 0 0-38 49.67 49.67 0 0 0-8.44-16 41.73 41.73 0 0 0-13.47-11 41.38 41.38 0 0 0-36 0 41.73 41.73 0 0 0-13.47 11 49.67 49.67 0 0 0-8.44 16 61.61 61.61 0 0 0-2.93 19Z" transform="translate(-406.68 -484)"/>
          <path d="M1513.32 592.75h-29.22a284.79 284.79 0 0 1-18.91-23 186.73 186.73 0 0 1-15.5-24.91 33.12 33.12 0 0 1-4.14-.33c-1.68-.21-3.41-.45-5.19-.73s-3.52-.59-5.2-1-3.05-.73-4.13-1.05v25.32q0 6.81.4 11.28a16.61 16.61 0 0 0 2.11 7.22 10 10 0 0 0 5.11 4.06 35.57 35.57 0 0 0 9.42 1.78v1.95q-6.16-.33-12.34-.49t-12.33-.16q-6 0-11.85.16t-11.69.49v-1.95a32.78 32.78 0 0 0 9.09-1.78 10 10 0 0 0 5-4.06 16.48 16.48 0 0 0 2.11-7.22q.4-4.47.41-11.28v-54.16q0-6.81-.41-11.28a16.48 16.48 0 0 0-2.11-7.22 10 10 0 0 0-5-4.06 32.78 32.78 0 0 0-9.09-1.78v-1.95q5.19.33 10.39.49t10.39.16c1.51 0 3.4-.08 5.68-.24l7.22-.57q3.81-.33 7.71-.57c2.6-.16 4.92-.24 7-.24a60 60 0 0 1 14.28 1.78 40.94 40.94 0 0 1 13.15 5.68 32.21 32.21 0 0 1 9.58 9.9 26.53 26.53 0 0 1 3.73 14.29 23.72 23.72 0 0 1-2 10.06 24.9 24.9 0 0 1-5.43 7.63 29.09 29.09 0 0 1-8 5.35 42.69 42.69 0 0 1-9.66 3.09q2.28 3.9 5.36 8.76t6.9 9.91q3.81 5 8.11 10a78.69 78.69 0 0 0 9.09 8.92 58.07 58.07 0 0 0 9.82 6.66 27 27 0 0 0 10.23 3.16ZM1431 536.27a42.31 42.31 0 0 0 15.09 2.76q12.66 0 19.64-5.85t7-19a22.42 22.42 0 0 0-2-9.66 23 23 0 0 0-5.6-7.46 25.56 25.56 0 0 0-8.2-4.87 27.59 27.59 0 0 0-9.65-1.71q-4.07 0-8 .33a36.73 36.73 0 0 0-7.63 1.46c-.21 5.19-.38 10.44-.48 15.74s-.16 11-.16 17.21Z" transform="translate(-406.68 -484)"/>
        </svg>
          <h2 className="DesktopLayer__title">
            {t('desktopLayer.title')}
          </h2>
          <p className="DesktopLayer__description" dangerouslySetInnerHTML={{ __html: t(`desktopLayer.description`) }}></p>
          <img src={QR} alt="QR code" className="DesktopLayer__qr" />
        </div>
      </div>
    </div>
  )
}

export default DesktopLayer
