/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF, useTexture } from '@react-three/drei'
import { useSpring, a, config } from '@react-spring/three'

export default function SimpleLipstick({ ...props }) {
  const group = useRef()
  const innerGroup = useRef()
  const { nodes, materials } = useGLTF('/models/Dior_Rouge_lipstick_simple4.gltf')

  const spring = useSpring({
    from: {
      rotation: [0, Math.PI, -0.2],
      position: [0, 2, 0]
    },
    to: {
      rotation: [0, 0, 0],
      position: [0, 0, 0]
    },
    config: config.molasses,
  })

  const [
    lipstickNormal,
    baseInside,
    metalBase,
    midNightBack,
    metalRefill,
    matcap
  ] = useTexture([
    '/textures/normals/Lipstic_Normal.png',
    '/textures/matcaps/Base_Inside.jpg',
    '/textures/matcaps/Metal_Base.jpg',
    '/textures/matcaps/Mid_night_black.jpg',
    '/textures/matcaps/Metal_Refil_2.jpg',
    '/textures/matcaps/999_Satin.jpg',
  ])

  useFrame(({ clock }) => {
    const elapsed = clock.getElapsedTime()
    innerGroup.current.rotation.y = (elapsed * -0.5) + (Math.PI / 2)
    innerGroup.current.rotation.z += Math.sin(elapsed) * 0.001
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <a.group ref={innerGroup} {...spring}>
        <mesh geometry={nodes.Mesh.geometry} material={materials.Base_Inside_mat}>
          <meshMatcapMaterial
            attach="material"
            matcap={baseInside}
          />
        </mesh>
        <mesh geometry={nodes.Mesh_1.geometry} material={materials.Metal_Base_mat}>
          <meshMatcapMaterial
            attach="material"
            matcap={metalBase}
          />
        </mesh>
        <mesh geometry={nodes.Mesh_2.geometry} material={nodes.Mesh_2.material}>
          <meshMatcapMaterial
            attach="material"
            matcap={midNightBack}
          />
        </mesh>
        <mesh
          geometry={nodes.Lipstick_Color_mdl.geometry}
          material={materials.Lipstick_Color_mat}
        >
          <meshMatcapMaterial
            attach="material"
            matcap={matcap}
            normalMap={lipstickNormal}
            normalMap-flipY={false}
          />
        </mesh>
        <mesh
          geometry={nodes.Refill_Metal_mdl.geometry}
          material={materials.Refill_Metal_Mat}
        >
          <meshMatcapMaterial
            attach="material"
            matcap={metalRefill}
          />
        </mesh>
        <mesh
          geometry={nodes.Refill_Plastic_mdl.geometry}
          material={nodes.Refill_Plastic_mdl.material}
        >
          <meshMatcapMaterial
            attach="material"
            matcap={midNightBack}
          />
        </mesh>
      </a.group>
    </group>
  )
}
