import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import './LangSwitcher.css'

export default function LangSwitcher() {
  const { i18n } = useTranslation()

  const [lngs] = useState({
    en: { nativeName: 'English' },
    it: { nativeName: 'Italiano' },
  })

  return (
    <div className="Lang-Switcher">
      {Object.keys(lngs).map((lng) => (
        // style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}
        <button className="Lang-Switcher__btn" key={lng} style={{ opacity: i18n.resolvedLanguage === lng ? 1 : 0.6 }} type="submit" onClick={() => {
          i18n.changeLanguage(lng)
        }}>
          {lng}
        </button>
      ))}
    </div>
  )
}
