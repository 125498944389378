import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import './Terms.css'

import Informative from '../../components/Informative'

export default function Privacy() {
  const { i18n } = useTranslation('translations')
  const [getTermsAndConditions, setTermsAndConditions] = useState(null)

  useEffect(() => {
    const grabTermsAndConditions = async () => {
      const termsAndConditionsFile = await import(
        `./markdown/${i18n.resolvedLanguage}.term.md`
      )
      const termsAndConditionsContent = await fetch(termsAndConditionsFile.default)
      const text = await termsAndConditionsContent.text()
      setTermsAndConditions(text)
    };
    grabTermsAndConditions()
  }, [i18n.resolvedLanguage])

  useEffect(() => {
    document.body.classList.add('t-light')
    return () => {
      document.body.classList.remove('t-light')
    }
  }, [])

  return (
    <div className="Terms">
      <Informative>
        <ReactMarkdown children={getTermsAndConditions} />
      </Informative>
    </div>
  )
}
