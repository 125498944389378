import { useSpring, config } from '@react-spring/three'

export default function useFadeInAnimation() {
  const { opacity } = useSpring({
    to: {
      opacity: 1
    },
    from: { opacity: 0 },
    config: config.default,
  })

  return opacity
}
