import { useState, useMemo } from 'react'
import { animated, config, useChain, useSpring, useSpringRef } from 'react-spring'

import './Play.css'
import './Counter.css'

import Instructions from '../../components/Instructions'
import { useGame, usePrevious } from '../../hooks'

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function Counter({ value }) {
  const [reverse, set] = useState(false)

  const [x, y] = useMemo(() => (
    [
      getRandomInt(100, 200),
      getRandomInt(300, 450)
    ]
  ), [])

  const scaleRef = useSpringRef()
  const scale = useSpring({
    from: { scale: 1.3, opacity: 0 },
    to: { scale: 1, opacity: 1 },
    config: config.gentle,
    // reverse,
    ref: scaleRef
  })

  const animationRef = useSpringRef()
  const animation = useSpring({
    from: { opacity: 0, transform: `translate3d(0, 100%, 0)` },
    to: { opacity: 1, transform: `translate3d(0, 0, 0)` },
    config: config.gentle,
    reverse,
    ref: animationRef
  })

  const fillRef = useSpringRef()
  const fill = useSpring({
    from: { strokeDashoffset: 410, strokeDasharray: 410 },
    to: { strokeDashoffset: 0 },
    config: config.default,
    reverse,
    ref: fillRef
  })

  const addRef = useSpringRef()
  const add = useSpring({
    from: { x: -10, opacity: 0 },
    to: { x: 0, opacity: 1 },
    config: config.gentle,
    reverse,
    onRest: () => set(true),
    ref: addRef
  })

  useChain([scaleRef, fillRef, animationRef, addRef], [0, 0.1, 0.2, 0.3])

  return value && (
    <animated.div
      style={{
        ...scale,
        x,
        y,
      }}
      className="Play__counter"
    >
      <div className="Counter">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.26 165.15" className="Counter__circle">
          {
            value > 0
              ? <animated.path style={add} id="plus" fill="#fff" d="M38.36 80.42H22.65v15.46h-3V80.42H3.85v-3h15.8V62h3v15.42h15.71Z"/>
              : <animated.path style={add} id="minus" fill="#fff" d="M32.54 81.12H9.67V76.8h22.87Z"/>
          }
          <animated.path style={fill} id="circle" d="M32.27 40.91A78.57 78.57 0 1 1 29.82 120" fill="none" stroke="#fff" strokeMiterlimit="10"/>
        </svg>
        <div className="Counter__value">
          <div className="Counter__mask">
            <animated.div style={animation}>
              { Math.abs(value) }
            </animated.div>
          </div>
        </div>
      </div>
    </animated.div>
  )
}

export default function Play() {
  const [closed, set] = useState(false)

  const score = useGame(state => state.score)
  const playing = useGame(state => state.playing)
  const completed = useGame(state => state.completed)
  const prevScore = usePrevious(score)

  const diff = useMemo(() => (score - prevScore || 0), [score, prevScore])
  const open = useMemo(() => (
    !closed && !playing && !completed
  ), [closed, playing, completed])

  return (
    <div className="Play">
      {
        useMemo(() =>
          <Instructions
            open={open}
            onClose={() => set(true)}
          />,
        [open])
      }
      { playing && (
        <Counter
          key={score}
          value={diff}
        />
      )}
    </div>
  )
}
