import { Suspense, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Canvas } from '@react-three/fiber'

import './Retry.css'

import Button from '../../components/Button'
import ClosedLipstick from '../../components/Lipstick/Closed'

import { useGame, useTheme } from '../../hooks'

function Retry() {
  const { t } = useTranslation('translations')
  const navigate = useNavigate()

  const startGame = useGame(state => state.start)
  const resetGame = useGame(state => state.reset)
  const resetTheme = useTheme(state => state.reset)

  const onClick = useCallback((e) => {
    resetGame()
    resetTheme()
    startGame()
    navigate('/play', { replace: true })
  }, [resetTheme, resetGame, startGame, navigate])

  return (
    <div className="Retry">
      <div className="Retry__model">
        <Canvas
          gl={{
            alpha: true,
            antialias: true,
          }}
          dpr={[1, 2]}
        >
          <Suspense fallback={null}>
            <ClosedLipstick
              scale={0.3}
              position={[0, 0.4, 0]}
            />
          </Suspense>
        </Canvas>
      </div>
      <div className="Retry__content">
        <h1 className="Retry__title">{t('retry.title')}</h1>
        <p className="Retry__description">{t('retry.description')}</p>
        <Button secondary>
          <button onClick={onClick}>
            {t('retry.play')}
          </button>
        </Button>
      </div>
    </div>
  )
}

export default Retry
