/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useSpring, a, config } from '@react-spring/three'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Dior_Rouge_lipstick_closed.gltf')

  const spring = useSpring({
    from: {
      rotation: [0, Math.PI, -0.2],
      position: [0, 20, 0]
    },
    to: {
      rotation: [0, 0, 0],
      position: [0, 0, 0]
    },
    config: config.molasses,
  })

  const [
    // lipstickNormal,
    baseInside,
    metalBase,
    midNightBack,
    capRed,
    // metalRefill,
    // matcap
  ] = useTexture([
    // '/textures/normals/Lipstic_Normal.png',
    '/textures/matcaps/Base_Inside.jpg',
    '/textures/matcaps/Metal_Base.jpg',
    '/textures/matcaps/Mid_night_black.jpg',
    '/textures/matcaps/Cap_Red.jpg',
    // '/textures/matcaps/Metal_Refil_2.jpg',
    // '/textures/matcaps/999_Satin.jpg',
  ])


  return (
    <group ref={group} {...props} dispose={null}>
      <a.group {...spring}>
        <mesh geometry={nodes.Mesh.geometry} material={materials.Base_Inside_mat}>
          <meshMatcapMaterial
            attach="material"
            matcap={baseInside}
          />
        </mesh>
        <mesh geometry={nodes.Mesh_1.geometry} material={materials.Metal_Base_mat}>
          <meshMatcapMaterial
            attach="material"
            matcap={metalBase}
          />
        </mesh>
        <mesh geometry={nodes.Mesh_2.geometry} material={nodes.Mesh_2.material}>
          <meshMatcapMaterial
            attach="material"
            matcap={midNightBack}
          />
        </mesh>
        <mesh geometry={nodes.Mesh002.geometry} material={nodes.Mesh002.material}>
          <meshMatcapMaterial
            attach="material"
            matcap={metalBase}
          />
        </mesh>
        <mesh geometry={nodes.Mesh002_1.geometry} material={materials.Cap_red_mat}>
          <meshMatcapMaterial
            attach="material"
            matcap={capRed}
          />
        </mesh>
        <mesh geometry={nodes.Mesh002_2.geometry} material={nodes.Mesh002_2.material}>
          <meshMatcapMaterial
            attach="material"
            matcap={midNightBack}
          />
        </mesh>
      </a.group>
    </group>
  )
}

useGLTF.preload('/models/Dior_Rouge_lipstick_closed.gltf')
