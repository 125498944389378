/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { BufferAttribute } from 'three/src/core/BufferAttribute'
import { a } from '@react-spring/three'

import { useColorAnimation, useFadeInAnimation } from '../../hooks'

export default function Environment({ ...props }) {
  const group = useRef()

  const { nodes, materials } = useGLTF('/models/Dior_Rouge_Enviroment.gltf')
  const aoRoller = useTexture('/textures/ambientOcclusions/roller2.jpg')

  const color = useColorAnimation()
  const alpha = useFadeInAnimation()

  useEffect(() => {
    group.current.traverse((o) => {
      if (!o.isMesh) return
      const attributes = new BufferAttribute(o.geometry.attributes.uv.array, 2)
      o.geometry.setAttribute('uv2', attributes)
    })
  }, [group])

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.35, -0.52, -1.02]} rotation={[Math.PI / 2, 0, 0]} scale={0.1}>
        <mesh
          geometry={nodes.Env_Base_mdl.geometry}
          material={nodes.Env_Base_mdl.material}
          position={[-3.03, -27.78, -0.96]}
          scale={38.75}
        >
          <a.meshStandardMaterial
            transparent
            opacity={alpha}
            attach="material"
            aoMap={aoRoller}
            aoMap-flipY={false}
            color={color}
          />
        </mesh>
        <mesh
          geometry={nodes.Env_Down_mdl.geometry}
          material={nodes.Env_Down_mdl.material}
          position={[-3.03, -27.78, -0.96]}
          scale={38.75}
        >
          <a.meshStandardMaterial
            transparent
            opacity={alpha}
            attach="material"
            aoMap={aoRoller}
            aoMap-flipY={false}
            color={color}
          />
        </mesh>
        <mesh
          geometry={nodes.Env_Fill.geometry}
          material={materials['Roller_Mat.001']}
          position={[-48.76, -25.57, 0.44]}
          scale={[32.18, 4.41, 1]}
        >
          <a.meshStandardMaterial
            transparent
            opacity={alpha}
            attach="material"
            aoMap={aoRoller}
            aoMap-flipY={false}
            color={color}
          />
        </mesh>
        <mesh
          geometry={nodes.Env_Glass_mdl.geometry}
          material={materials.Glass_Mat}
          renderOrder={1}
          position={[-3.03, -27.78, -4.21]}
          scale={[38.75, 38.75, 52.27]}
        />
        <mesh
          geometry={nodes.Env_Wall_mdl.geometry}
          material={nodes.Env_Wall_mdl.material}
          position={[-27.94, -85.48, 3.24]}
          scale={[155.89, 1, 14.12]}
        >
          <a.meshStandardMaterial
            transparent
            opacity={alpha}
            attach="material"
            aoMap={aoRoller}
            aoMap-flipY={false}
            color={color}
          />
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Dior_Rouge_Enviroment.gltf')
