import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import './Informative.css'

export default function Informative({ children }) {
  const scrollerRef = useRef()
  const navigate = useNavigate()

  const onClose = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const onScroll = useCallback(e => {
    const factory = scrollerRef.current.scrollTop > 80 ? 'add' : 'remove'
    document.body.classList[factory]('is-scrolled')
  }, [])

  useEffect(() => {
    scrollerRef.current.addEventListener('scroll', onScroll)
    return () => {
      scrollerRef.current?.removeEventListener('scroll', onScroll)
      document.body.classList.remove('is-scrolled')
    }
  }, [])

  return (
    <div  className="Informative">
      <button className="Informative__close" onClick={onClose}>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 89">
          <path className="Informative__close-path" d="M2 44.359v-.002A42.266 42.266 0 0 1 44.171 2l-.002-1 .005 1A42.36 42.36 0 1 1 2 44.359Z" stroke="#000" strokeWidth="2"/>
          <path d="M55.328 58.17 44.057 46.897 32.965 57.99l-2.147-2.146L41.91 44.75l-11.33-11.33 2.146-2.147 11.331 11.33 11.032-11.032 2.147 2.147L46.204 44.75l11.271 11.272-2.147 2.146Z" fill="#000"/>
        </svg>
      </button>
      <div ref={scrollerRef} className="Informative__container">
        { children }
      </div>
    </div>
  )
}
