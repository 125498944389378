import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import './Privacy.css'

import Informative from '../../components/Informative'

export default function Privacy() {
  const { i18n } = useTranslation('translations')

  const [getPrivacy, setPrivacy] = useState(null)

  useEffect(() => {
    const grabPrivacy = async () => {
      const privacyFile = await import(
        `./markdown/${i18n.resolvedLanguage}.privacy.md`
      )
      const privacyContent = await fetch(privacyFile.default)
      const text = await privacyContent.text()
      setPrivacy(text)
    };
    grabPrivacy()
  }, [i18n.resolvedLanguage])

  useEffect(() => {
    document.body.classList.add('t-light')
    return () => {
      document.body.classList.remove('t-light')
    }
  }, [])

  return (
    <div className="Privacy">
      <Informative>
        <ReactMarkdown children={getPrivacy} />
      </Informative>
    </div>
  )
}
