import * as React from 'react'
import { Flow } from 'three-stdlib'

export default React.forwardRef(({ children, curve }, ref) => {
  const [object3D, setObj] = React.useState()
  const original = React.useRef()
  const modifier = React.useRef()

  React.useImperativeHandle(ref, () => ({
    ...modifier.current,
    moveAlongCurve: (val) => {
      modifier.current?.moveAlongCurve(val)
    },
  }))

  React.useEffect(() => {
    if (!modifier.current && original.current && ref) {
      modifier.current = new Flow(original.current)
      setObj(modifier.current.object3D)
    }
  }, [children, ref])

  React.useEffect(() => {
    if (original.current && curve) {
      modifier.current?.updateCurve(0, curve)
    }
  }, [curve])

  return object3D ? (
    <primitive object={object3D} />
  ) : (
    React.cloneElement(React.Children.only(children), {
      ref: original,
    })
  )
})
