import classNames from 'classnames'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Canvas } from '@react-three/fiber'

import './Win.css'
import './Form.css'

import Button from '../../components/Button'
import ClosedLipstick from '../../components/Lipstick/Closed'

import { useCanvasResizer } from '../../hooks'

const FORM_ENDPOINT = `https://rougediorgame.nohup.it/api/`

function Win() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('translations')
  const { register, handleSubmit, formState: { errors } } = useForm()
  const size = useCanvasResizer({ threshold: 0 })

  const onSubmit = async formData => {
    const body = new FormData()

    for (const name in formData) {
      body.append(name, formData[name])
    }
    // append language for email
    body.append('language', i18n.resolvedLanguage)

    try {
      const response = await fetch(FORM_ENDPOINT, {
        body,
        method: 'POST',
      })

      const result = await response.json()

      if (result.success) {
        navigate('/thank-you', { replace: true })
      } else {
        throw new Error('Already used email')
      }
    } catch(error) {
      navigate('/error', { replace: true })
    }
  }

  return (
    <div className="Win">
      <div className="Win__model">
        <Canvas
          style={{
            ...size
          }}
          gl={{
            alpha: true,
            antialias: true,
          }}
          dpr={[1, 2]}
        >
          <Suspense fallback={null}>
            <ClosedLipstick
              scale={0.3}
              position={[0, 0.6, 0]}
            />
          </Suspense>
        </Canvas>
      </div>
      <div className="Win__content">
        <h1 className="Win__title">{t('win.title')}</h1>
        <p className="Win__description">{t('win.description')}</p>

        <form className="Form" onSubmit={handleSubmit(onSubmit)}>
          <legend>
            {t('form.requiredFields')}
          </legend>

          <div className="Form__row">
            <label className="Input">
              <input
                name="firstName"
                {...register('firstName', { required: true })}
                className={classNames({ 'has-error': errors.firstName })}
                placeholder={t('form.firstName')}
              />
              <span className="visually-hidden">
                {t('form.firstName')}
              </span>
            </label>
          </div>

          <div className="Form__row">
            <label className="Input">
              <input
                name="lastName"
                {...register('lastName', { required: true })}
                className={classNames({ 'has-error': errors.lastName })}
                placeholder={t('form.lastName')}
              />
              <span className="visually-hidden">
                {t('form.lastName')}
              </span>
            </label>
          </div>

          <div className="Form__row Form__divider">
            <label className="Input">
              <input
                name="email"
                type="email"
                {...register('email', { required: true })}
                className={classNames({ 'has-error': errors.email })}
                placeholder={t('form.email')}
              />
              <span className="visually-hidden">
                {t('form.email')}
              </span>
            </label>
          </div>

          <div className="Form__row">
            <label
              className={classNames('Checkbox', {
                'has-error': errors.privacyAccept
              })}
            >
              <input
                className="visually-hidden"
                type="checkbox"
                {...register('privacyAccept', { required: true })}
              />
              <span className="Checkbox__check"></span>
              <span className="Checkbox__label">
                {t('form.privacyAccept')}
                <Link to="/privacy-policy">privacy policy.</Link>
              </span>
            </label>
          </div>

          <div className="Form__row">
            <label
              className={classNames('Checkbox', {
                'has-error': errors.marketingAccept
              })}
            >
              <input
                className="visually-hidden"
                type="checkbox"
                {...register('marketingAccept', { required: true })}
              />
              <span className="Checkbox__check"></span>
              <span className="Checkbox__label">
                {t('form.marketingAccept')}
                <Link to="/privacy-policy">privacy policy.</Link>
              </span>
            </label>
          </div>

          <div className="Form__row">
            <div className="Form__nb">
              <span dangerouslySetInnerHTML={{ __html: t('form.nb') }} />
            </div>
          </div>

          <div className="Form__submit">
            <Button secondary>
              <button type="submit">
                {t('form.submit')}
              </button>
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Win
