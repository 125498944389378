import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import './Cookie.css'

import Button from '../../components/Button'
import Informative from '../../components/Informative'

export default function Privacy() {
  const cookieRef = useRef()
  const { t, i18n } = useTranslation('translations')

  const [getPrivacy, setPrivacy] = useState(null)

  useEffect(() => {
    const grabPrivacy = async () => {
      const privacyFile = await import(
        `./markdown/${i18n.resolvedLanguage}.cookie.md`
      )
      const privacyContent = await fetch(privacyFile.default)
      const text = await privacyContent.text()
      setPrivacy(text)
    };
    grabPrivacy()
  }, [i18n.resolvedLanguage])

  useEffect(() => {
    document.body.classList.add('t-light')
    return () => {
      document.body.classList.remove('t-light')
    }
  }, [])

  useEffect(() => {
    if (cookieRef.current && window.Optanon) {
      window?.Optanon?.initializeCookiePolicyHtml(cookieRef.current)
    }
  }, [i18n.resolvedLanguage])

  return (
    <div className="Cookie">
      <Informative>
        <ReactMarkdown children={getPrivacy} />
        <div style={{marginTop: 40}}>
          <Button primary small>
            <button onClick={() => window.Optanon.ToggleInfoDisplay()}>
              {t('cookie.manage')}
            </button>
          </Button>
        </div>
        <div ref={cookieRef} id="ot-sdk-cookie-policy"></div>
      </Informative>
    </div>
  )
}
