import { useEffect} from 'react'
import { useLocation } from 'react-router-dom'
// import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    // ReactGA.initialize('UA-134867111-3')
    TagManager.initialize({ gtmId: 'GTM-TTWCN6F' })
  }, [])

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'reactPageView',
        pageUrl: location.pathname + location.search,
        pageType: 'PageView',
      }
    })
    // ReactGA.pageview(location.pathname + location.search)
  }, [location])
}

export default usePageTracking
