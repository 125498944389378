// import { useState } from 'react'
// import { useTransition, animated, config } from 'react-spring'
// import { Link } from 'react-router-dom'

// import './Logo.css'

// export default function Logo() {
//   const [show] = useState(true)
//   const transitions = useTransition(show, {
//     from: { opacity: 0, y: -16 },
//     enter: { opacity: 1, y: 0 },
//     leave: { opacity: 0 },
//     delay: 2000,
//     config: config.molasses,
//   })

//   return transitions(
//     (styles, item) => item &&
//       <animated.svg
//         id="site-logo"
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 1695.3 474.1"
//         xmlSpace="preserve"
//         style={styles}
//       >
//         <path d="M1383.3 17.8h28.4c48.7 0 127.5 19.2 127.5 111.1 0 84.3-69.9 112.8-134.7 112.8H1363V37c0-11.6 7.2-19.2 20.3-19.2m305.1 443.6c-59 6.2-91.5-90.5-121.4-136.4-22.3-33.9-69.6-68.6-116.2-75.4 76.8-4.5 162.8-29.1 162.8-116.2 0-70.6-43.5-124.1-203.3-124.1h-184.1c-2.4 0-4.8 1.4-4.8 4.1s2.4 4.5 4.8 4.5h42.2c8.9 0 17.5 6.2 17.5 23.3V435c0 8.2-6.2 20.6-17.1 20.6h-41.5c-3.4 0-4.8 2.7-4.8 4.1s1.4 4.5 4.8 4.5h195.4c2.7 0 5.1-1.4 5.1-4.1s-1.7-4.5-5.5-4.5h-39.4c-5.1 0-19.2-3.4-19.2-18.9V250.6h20.2c96.7 0 103.9 104.9 151.9 164.9 41.1 51.4 95 57.9 125.5 57.9 13 0 21.9-.3 30.2-2.4 5.1-1.7 6.1-10.6-3.1-9.6m-724.7 12.7c150.1 0 244.8-106.3 244.8-236.9S1114.8 0 963.7 0C812.8 0 718.9 106.3 718.9 236.9s97.7 237.2 244.8 237.2M801.9 236.9c0-125.8 56.2-227.6 161.8-227.6 103.5 0 161.8 101.8 161.8 227.6s-53.1 227.6-161.8 227.6c-105.3.4-161.8-101.8-161.8-227.6M510.5 9.3c-3.4 0-6.5 1.4-6.5 4.1s1.7 4.5 4.8 4.5h38.7c8.9 0 17.5 6.2 17.5 23.3V435c0 8.2-6.2 20.6-17.1 20.6h-38.4c-4.5 0-4.8 3.4-4.8 4.8s-.3 3.8 4.8 3.8H696c2.7 0 6.5-.3 6.5-3.1s-.7-5.5-5.5-5.5h-36c-5.1 0-19.2-3.1-19.2-18.9V37c0-11.3 7.2-18.9 20.2-18.9h35.3c3.1 0 4.8-1.7 4.8-4.1 0-2.4-1.7-4.5-5.8-4.5H510.5v-.2zM142.6 442.9c0 9.9 4.5 13 11 13h58.3c143.6 0 195.8-110 195.8-220.4S352.4 18.2 231.1 18.2h-79.2c-8.2 0-8.9 6.9-8.9 9.9l-.4 414.8zM5.1 9.3h220.1c189.2 0 263.3 111.1 263.3 228.7 0 119.6-95 226.3-275.6 226.3H5.5c-3.8 0-5.1-2.4-5.1-4.5 0-2.4 2.4-4.5 5.8-4.5h38.7c12 0 19.9-7.2 19.9-20.6V39.4c0-9.9-4.8-20.9-20.6-20.9H4.8C1.7 18.5 0 16.4 0 14c0-2 .7-4.7 5.1-4.7"/>
//       </animated.svg>
//   )
// }

// import { useState } from 'react'
// import { useTransition, animated, config } from 'react-spring'

import './Logo.css'

export default function Logo() {
  // const [show] = useState(true)
  // const transitions = useTransition(show, {
  //   from: { opacity: 0, y: -16 },
  //   enter: { opacity: 1, y: 0 },
  //   leave: { opacity: 0 },
  //   delay: 2000,
  //   config: config.molasses,
  // })

  return (// transitions(
    // (styles, item) => item &&
    // <Link to="/" id="site-logo">
    <svg
      id="site-logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1695.3 474.1"
      xmlSpace="preserve"
      // style={styles}
    >
      <path d="M1383.3 17.8h28.4c48.7 0 127.5 19.2 127.5 111.1 0 84.3-69.9 112.8-134.7 112.8H1363V37c0-11.6 7.2-19.2 20.3-19.2m305.1 443.6c-59 6.2-91.5-90.5-121.4-136.4-22.3-33.9-69.6-68.6-116.2-75.4 76.8-4.5 162.8-29.1 162.8-116.2 0-70.6-43.5-124.1-203.3-124.1h-184.1c-2.4 0-4.8 1.4-4.8 4.1s2.4 4.5 4.8 4.5h42.2c8.9 0 17.5 6.2 17.5 23.3V435c0 8.2-6.2 20.6-17.1 20.6h-41.5c-3.4 0-4.8 2.7-4.8 4.1s1.4 4.5 4.8 4.5h195.4c2.7 0 5.1-1.4 5.1-4.1s-1.7-4.5-5.5-4.5h-39.4c-5.1 0-19.2-3.4-19.2-18.9V250.6h20.2c96.7 0 103.9 104.9 151.9 164.9 41.1 51.4 95 57.9 125.5 57.9 13 0 21.9-.3 30.2-2.4 5.1-1.7 6.1-10.6-3.1-9.6m-724.7 12.7c150.1 0 244.8-106.3 244.8-236.9S1114.8 0 963.7 0C812.8 0 718.9 106.3 718.9 236.9s97.7 237.2 244.8 237.2M801.9 236.9c0-125.8 56.2-227.6 161.8-227.6 103.5 0 161.8 101.8 161.8 227.6s-53.1 227.6-161.8 227.6c-105.3.4-161.8-101.8-161.8-227.6M510.5 9.3c-3.4 0-6.5 1.4-6.5 4.1s1.7 4.5 4.8 4.5h38.7c8.9 0 17.5 6.2 17.5 23.3V435c0 8.2-6.2 20.6-17.1 20.6h-38.4c-4.5 0-4.8 3.4-4.8 4.8s-.3 3.8 4.8 3.8H696c2.7 0 6.5-.3 6.5-3.1s-.7-5.5-5.5-5.5h-36c-5.1 0-19.2-3.1-19.2-18.9V37c0-11.3 7.2-18.9 20.2-18.9h35.3c3.1 0 4.8-1.7 4.8-4.1 0-2.4-1.7-4.5-5.8-4.5H510.5v-.2zM142.6 442.9c0 9.9 4.5 13 11 13h58.3c143.6 0 195.8-110 195.8-220.4S352.4 18.2 231.1 18.2h-79.2c-8.2 0-8.9 6.9-8.9 9.9l-.4 414.8zM5.1 9.3h220.1c189.2 0 263.3 111.1 263.3 228.7 0 119.6-95 226.3-275.6 226.3H5.5c-3.8 0-5.1-2.4-5.1-4.5 0-2.4 2.4-4.5 5.8-4.5h38.7c12 0 19.9-7.2 19.9-20.6V39.4c0-9.9-4.8-20.9-20.6-20.9H4.8C1.7 18.5 0 16.4 0 14c0-2 .7-4.7 5.1-4.7"/>
    </svg>
    // </Link>
  )
}
