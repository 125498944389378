import { Navigate, useLocation } from 'react-router-dom'

import { useGame } from '../../hooks'

export default function RequireGame ({ children }) {
  const played = useGame(state => state.played)
  const location = useLocation()

  if (!played) {
    // Redirect them to the /play page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/play" state={{ from: location }} replace />
  }

  return children
}
