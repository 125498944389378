import { Suspense, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Canvas, useThree } from '@react-three/fiber'
import { useSpringRef, useChain, useSpring, a, config } from '@react-spring/three'
import { animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { Euler } from 'three/src/math/Euler'

import './Home.css'
import top from './top.jpg'
import bottom from './bottom.jpg'

import Button from '../../components/Button'
import SimpleLipstick from '../../components/Lipstick/Simple'


function Draggable({ responsiveness = 20, children }) {
  const { size } = useThree()

  const euler = useMemo(() => new Euler(), [])

  const [spring, set] = useSpring(() => ({
    rotation: [0, 0, 0],
  }))

  const bind = useDrag(({ delta: [dx, dy] }) => {
    euler.y += (dx / size.width) * responsiveness
    set({ rotation: euler.toArray().slice(0, 3) })
  })

  return (
    <a.group {...bind()} {...spring}>
      {children}
    </a.group>
  )
}

export default function Home() {
  const scrollerRef = useRef()
  const scrollDestinationRef = useRef()
  const { t } = useTranslation('translations')

  const [, setY] = useSpring(() => ({
    immediate: false,
    from: {
      y: 0
    },
    onChange: props => {
      scrollerRef.current.scrollTop = props.value.y
    },
    reset: true,
    config: config.default,
  }))

  const titleSpringRef = useSpringRef()
  const titleStyles = useSpring({
    to: {
      opacity: 1
    },
    from: { opacity: 0 },
    ref: titleSpringRef,
    config: config.molasses,
  })

  const actionSpringRef = useSpringRef()
  const actionStyles = useSpring({
    to: {
      opacity: 1,
      y: 0,
    },
    from: { opacity: 0, y: -16 },
    ref: actionSpringRef,
    config: config.molasses,
  })

  useChain([titleSpringRef, actionSpringRef], [1, 1.6])

  return (
    <div ref={scrollerRef} className="Home">
      <div className="Home__content">
        <div className="Home__model">
          <Canvas
            gl={{
              alpha: true,
              antialias: true,
            }}
            dpr={[1, 2]}
          >
            <Suspense fallback={null}>
              <Draggable responsiveness={6}>
                <SimpleLipstick scale={3.5} position={[0, 0.2, 0]} />
              </Draggable>
            </Suspense>
          </Canvas>
        </div>
        <animated.div style={titleStyles} className="Home__el">
          <p className="Home__title"> { t('home.title') } </p>
        </animated.div>
        <animated.div style={actionStyles} className="Home__el">
          <Button secondary>
            <Link to="/play">
              { t('home.play') }
            </Link>
          </Button>
          <button className="Home__indicator bounce2" onClick={() => {
              setY.start({ y: scrollDestinationRef.current.getBoundingClientRect().top })
            }
          }>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133.54 55.96">
              <path d="M133.54 0 68.66 56h-3.53L0 0h12.12l54.87 46.87L121.46 0Z" fill="#fff"/>
            </svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.27 37.15" fill="#fff">
              <path d="M23.64 21.17 0 2.35 1.87 0l21.77 17.33L45.4 0l1.87 2.35-23.63 18.82z"/>
              <path d="M23.64 37.15 0 18.33l1.87-2.35 21.77 17.33L45.4 15.98l1.87 2.35-23.63 18.82z"/>
            </svg>
          </button>
          {/* <p className="Home__title home__subtitle"> { t('home.subtitle') } </p> */}
        </animated.div>
      </div>
      <div ref={scrollDestinationRef} className="Home__scroll">
        <img src={top} alt="Dior Rouge" />
        <div className="Home__excerpt">
          <h2>{t('excerpt.title')}</h2>
          <p> {t('excerpt.description')} </p>
        </div>
        <img src={bottom} alt="Dior Rouge" />
        <div className="Home__excerpt">
        <p> {t('excerpt.description2')} </p>
        </div>
        <footer className="Home__footer">
          <Link to="/privacy-policy"> {t('footer.privacy')} </Link>
          {/* <button onClick={() => window.Optanon.ToggleInfoDisplay()}> {t('footer.cookie')} </button> */}
          {/* <button id="ot-sdk-btn" className="ot-sdk-show-settings">{t('footer.cookie')}</button> */}
          <Link to="/cookie-policy"> {t('footer.cookie')} </Link>
          <Link to="/terms-conditions"> {t('footer.terms')} </Link>
        </footer>
      </div>
    </div>
  )
}
