import './Header.css'

import Logo from '../Logo'
import LangSwitcher from '../LangSwitcher'
import Score from '../Score'

export default function Header() {
  return (
    <header className="Header">
      <LangSwitcher />
      <Logo />
      <Score />
    </header>
  )
}
