import create from 'zustand'

const useTheme = create((set, get) => ({
  current: 0,

  finished: 0,

  colors: [

    // 999 SATIN       Lipstic_Normal      b9202d
    // 100 MATTE       Lipstic_Normal      ad564c
    // 720 VELVET      Velvet_Normal       b9494f
    // 999 VELVET      Velvet_Normal       b9202d
    // 772 MATTE       Lipstic_Normal      b44d50
    // 999 MATTE       Lipstic_Normal      b9202d
    // 219 SATIN       Lipstic_Normal      d58172
    // 434 SATIN       Lipstic_Normal      a64d44
    // 999 METALLIC    Lipstic_Normal      b9202d
    // 000 SATIN BALM  Lipstic_Normal      ece1dd

    '#b9202d',
    '#ad564c',
    '#b9494f',
    '#b9202d',
    '#b44d50',
    '#b9202d',
    '#d58172',
    '#a64d44',
    '#b9202d',
    '#ece1dd',
  ],

  next: (current) => set((state) => ({ current: (current + 1) % state.colors.length })),

  discard: () => set((state) => ({ finished: (state.finished + 1) % state.colors.length })),

  reset: () => set((state) => ({ finished: 0, current: 0 }))
}))

export default useTheme
