/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useGLTF, useAnimations, useTexture } from '@react-three/drei'
import { useGraph, useFrame } from '@react-three/fiber'
import { useSpring, animated, config } from '@react-spring/three'
import { useGesture } from '@use-gesture/react'
import { LoopOnce } from 'three/src/constants'
import { SkeletonUtils } from 'three-stdlib'

import { useTheme, useGame } from '../../hooks'

const MINIMUM_TIME = 10
const MINIMUM_RANGE_TIME = 11.1
const MAXIMUM_RANGE_TIME = 11.8
const NEXT_TIME = 14

export default function Lipstick({ order, ...props }) {
  const [hasPlayed, setHasPlayed] = useState(false)

  const { scene, animations } = useGLTF('/models/Dior_Rouge_lipstick_4.gltf')
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene])
  const { nodes } = useGraph(clone)
  const { ref, mixer, actions } = useAnimations(animations)

  const next = useTheme(state => state.next)
  const current = useTheme(state => state.current)
  const discard = useTheme(state => state.discard)
  const finished = useTheme(state => state.finished)
  const limit = useTheme(state => state.colors.length - 1)

  const score = useGame(state => state.score)
  const playing = useGame(state => state.playing)
  const paused = useGame(state => state.paused)
  const right = useGame(state => state.right)
  const error = useGame(state => state.error)
  const stop = useGame(state => state.stop)
  const complete = useGame(state => state.complete)
  const completed = useGame(state => state.completed)

  const inViewport = useMemo(() => (
    !completed
      ? order >= finished && order <= finished + 3
      : order === limit
  ), [order, limit, finished, completed])

  const { opacity } = useSpring({
    onStart: () => {
      if (!playing) {
        actions.Error.halt(0.5)
        actions.Right.halt(0.5)
      }
    },
    opacity: playing ? 1 : 0,
    onRest: () => {
      if (!playing) {
        setHasPlayed(false)
        actions.Error.stop()
        actions.Right.stop()
      }
    },
    config: config.gentle,
  })

  const [
    defaultNormal,
    velvetNormal,
    baseInside,
    capRed,
    metalBase,
    midNightBack,
    metalRefill,
    velvet720,
    velvet999,
    metallic999,
  ] = useTexture([
    '/textures/normals/Lipstic_Normal.png',
    '/textures/normals/Velvet_Normal.jpg',
    '/textures/matcaps/Base_Inside.jpg',
    '/textures/matcaps/Cap_Red.jpg',
    '/textures/matcaps/Metal_Base.jpg',
    '/textures/matcaps/Mid_night_black.jpg',
    '/textures/matcaps/Metal_Refil_2.jpg',
    '/textures/baseColors/720_Velvet_Base_color.png',
    '/textures/baseColors/999_Velvet_Base_color.png',
    '/textures/baseColors/999_Metallic_BaseColor.png',
  ])

  const matcaps = useTexture([
    '/textures/matcaps/999_Satin.jpg',
    '/textures/matcaps/100_Mat.jpg',
    '/textures/matcaps/Color_Velvet.jpg',
    '/textures/matcaps/Color_Velvet.jpg',
    '/textures/matcaps/772_Mat.jpg',
    '/textures/matcaps/999_Mat.jpg',
    '/textures/matcaps/219_Satin.jpg',
    '/textures/matcaps/434_Satin.jpg',
    '/textures/matcaps/999_Metallic.jpg',
    '/textures/matcaps/000_Balm_Satin.jpg',
  ])

  const matcap = useMemo(() => (
    matcaps[order]
  ), [matcaps, order])

  const normalMap = useMemo(() => {
    switch(order) {
      case 2:
      case 3:
        return velvetNormal
      default:
        return defaultNormal
    }
  }, [defaultNormal, velvetNormal, order])

  const map = useMemo(() => {
    switch(order) {
      case 2:
        return velvet720
      case 3:
        return velvet999
      case 8:
        return metallic999
      default:
        return null
    }
  }, [velvet720, velvet999, metallic999, order])

  const onDragEnd = useCallback(({ tap, down, movement: [mx, my] }) => {
    if (hasPlayed || tap || down || my < 0) {
      return // not my business!
    }

    const moment = actions.Error.time
    if (moment < MINIMUM_TIME) return // too early boy!

    const time = mixer.time
    setHasPlayed(true)

    if (moment > MINIMUM_RANGE_TIME && moment < MAXIMUM_RANGE_TIME) {
      actions.Error.stop()
      actions.Right.play()
      mixer.setTime(time / mixer.timeScale)

      right(score)
    } else {
      error(score)
    }
  }, [score, hasPlayed, actions, right, error, mixer])

  useGesture({
    onDragEnd,
  }, {
    target: document.body,
    enabled: !hasPlayed && playing && order === current,
  })

  useEffect(() => {
    if (!playing || order !== current) return

    actions.Error.play()
    mixer.setTime(8 / mixer.timeScale)
  }, [playing, current, limit, actions, mixer, order])

  useFrame(() => {
    if (paused || !playing || order !== current) return
    const moment = actions.Error.time || actions.Right.time

    // if no click in time, send error
    if (moment > MAXIMUM_RANGE_TIME && actions.Error.isRunning() && !hasPlayed) {
      setHasPlayed(true)
      error(score)
    }

    // call next scenario
    if (moment > NEXT_TIME) {
      next(current)

      if (order === limit) {
        setTimeout(complete, 150)
      }
    }
  })

  useEffect(() => {
    mixer.timeScale = paused ? 0 : 1 + current * 0.15
  }, [current, mixer, paused])

  useEffect(() => {
    actions.Error.loop = LoopOnce
    actions.Right.loop = LoopOnce

    mixer.addEventListener('finished', () => {
      actions.Error.stop()
      actions.Right.stop()
      discard()
    })

  }, [actions, complete, stop, discard, limit, mixer, order])

  return useMemo(() => (
    <group
      visible={inViewport}
      ref={ref}
      dispose={null}
    >
      <group
        position={[-0.35, -0.52, -1.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.1}
      >
        <primitive object={nodes.Root} />
        <skinnedMesh
          geometry={nodes.Mesh.geometry}
          skeleton={nodes.Mesh.skeleton}
        >
          <animated.meshMatcapMaterial
            transparent
            attach="material"
            opacity={opacity}
            matcap={baseInside}
          />
        </skinnedMesh>
        <skinnedMesh
          geometry={nodes.Mesh_1.geometry}
          skeleton={nodes.Mesh_1.skeleton}
        >
          <animated.meshMatcapMaterial
            transparent
            attach="material"
            opacity={opacity}
            matcap={metalBase}
          />
        </skinnedMesh>
        <skinnedMesh
          geometry={nodes.Mesh_2.geometry}
          skeleton={nodes.Mesh_2.skeleton}
        >
          <animated.meshMatcapMaterial
            transparent
            attach="material"
            opacity={opacity}
            matcap={midNightBack}
          />
        </skinnedMesh>
        <skinnedMesh
          geometry={nodes.Mesh002.geometry}
          skeleton={nodes.Mesh002.skeleton}
        >
          <animated.meshMatcapMaterial
            transparent
            attach="material"
            opacity={opacity}
            matcap={metalBase}
          />
        </skinnedMesh>
        <skinnedMesh
          geometry={nodes.Mesh002_1.geometry}
          skeleton={nodes.Mesh002_1.skeleton}
        >
          <animated.meshMatcapMaterial
            transparent
            attach="material"
            opacity={opacity}
            matcap={capRed}
          />
        </skinnedMesh>
        <skinnedMesh
          geometry={nodes.Mesh002_2.geometry}
          skeleton={nodes.Mesh002_2.skeleton}
        >
          <animated.meshMatcapMaterial
            transparent
            attach="material"
            opacity={opacity}
            matcap={midNightBack}
          />
        </skinnedMesh>
        <skinnedMesh
          geometry={nodes.Lipstick_Color_mdl.geometry}
          skeleton={nodes.Lipstick_Color_mdl.skeleton}
        >
          <animated.meshMatcapMaterial
            transparent
            attach="material"
            opacity={opacity}
            matcap={matcap}
            map={map}
            normalMap={normalMap}
            normalMap-flipY={false}
          />
        </skinnedMesh>
        <skinnedMesh
          geometry={nodes.Refill_Metal_mdl.geometry}
          skeleton={nodes.Refill_Metal_mdl.skeleton}
        >
          <animated.meshMatcapMaterial
            transparent
            attach="material"
            opacity={opacity}
            matcap={metalRefill}
          />
        </skinnedMesh>
        <skinnedMesh
          geometry={nodes.Refill_Plastic_mdl.geometry}
          skeleton={nodes.Refill_Plastic_mdl.skeleton}
        >
          <animated.meshMatcapMaterial
            transparent
            attach="material"
            opacity={opacity}
            matcap={midNightBack}
          />
        </skinnedMesh>
      </group>
    </group>
  ), [
    opacity,
    nodes,
    baseInside,
    capRed,
    inViewport,
    map,
    matcap,
    metalBase,
    metalRefill,
    midNightBack,
    normalMap,
    ref,
  ])
}

useGLTF.preload('/models/Dior_Rouge_lipstick_4.gltf')
