import { useEffect } from 'react'
import { useSpring, config } from '@react-spring/three'

import useTheme from './useTheme'
import usePrevious from './usePrevious'

export default function useColorAnimation() {
  const nextShade = useTheme((state) => state.colors[state.current])
  const prevShade = usePrevious(nextShade)
  const { color } = useSpring({ color: nextShade })

  useEffect(() => {
    // This animates as expected, from prevShade to nextShade.
    color.start({ from: prevShade, to: nextShade, config: config.gentle })
  }, [color, prevShade, nextShade])

  return color
}
