import React, { Suspense, useRef } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, useProgress } from '@react-three/drei'
import { useTransition, config, animated } from 'react-spring'
import { Vector3 } from 'three/src/math/Vector3'
import isMobile from 'is-mobile'

import './App.css'
import './i18n'

import Home from './pages/Home'
import Play from './pages/Play'
import Win from './pages/Win'
import Retry from './pages/Retry'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import Cookie from './pages/Cookie'
import Submission from './pages/Submission'

import Header from './components/Header'
import Tape from './components/Tape'
import Environment from './components/Environment'
import Lipstick from './components/Lipstick'
import Effects from './components/Effects'
import RequireGame from './components/RequireGame'
import DesktopLayer from './components/DesktopLayer'

import { usePageTracking, useGameChecker, useGameRestart, useCanvasResizer } from './hooks'

function Rig() {
  const controls = useRef()
  const target = new Vector3(...[-1.3609317679842616, 1.5838684643808971, -2.6611763790748726])

  return (
    <OrbitControls
      ref={controls}
      target={target}
      enablePan={false}
      enableZoom={false}
      enableRotate={false}
    />
  )
}

function Pages() {
  const { active } = useProgress()
  const location = useLocation()

  useGameChecker()
  useGameRestart()
  usePageTracking()

  const transition = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
    trail: 200,
  })

  return !active && transition((props, item) => (
    <animated.div className="App__transitioner" style={props}>
      <Routes location={item}>
        <Route exact path="/" element={<Home />} />
        <Route path="/play" element={<Play />} />
        <Route path="/win" element={
          <RequireGame>
            <Win />
          </RequireGame>
        } />
        <Route path="/retry" element={
          <RequireGame>
            <Retry />
          </RequireGame>
        } />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/cookie-policy" element={<Cookie />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/thank-you" element={<Submission kind="success" />} />
        <Route path="/error" element={<Submission kind="error" />} />
      </Routes>
    </animated.div>
  ))
}

function GameCanvas({ children }) {
  const size = useCanvasResizer()

  return (
    <Canvas
      concurrent
      colorManagement
      style={{
        ...size,
      }}
      camera={{
        position: [2.39, 2.06, 3.39],
        fov: 64,
      }}
      gl={{
        alpha: false,
        antialias: false,
        powerPreference: 'high-performance',
      }}
      dpr={[1, 2]}
    >
      { children }
    </Canvas>
  )
}

function Lipsticks() {
  return Array(10)
    .fill()
    .map((_, order) => (
      <Lipstick
        key={order}
        order={order}
      />
    ))
}


function App() {
  if (isMobile()) {
    return (
      <div className="App">
        <Header />
        <Pages />
        <GameCanvas>
          <ambientLight args={[0xffffff, 1]} />
          <Suspense fallback={null}>
            <Lipsticks />
          </Suspense>
          <Suspense fallback={null}>
            <Environment />
            <Tape />
          </Suspense>
          <Suspense fallback={null}>
            <Effects />
          </Suspense>
          <Rig />
        </GameCanvas>
      </div>
    )
  } else {
    return (
      <DesktopLayer />
    )
  }
}

export default App
