import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useGame from './useGame'
import useTheme from './useTheme'

export default function useGameRestart() {
  const location = useLocation()
  const resetGame = useGame(state => state.reset)
  const resetTheme = useTheme(state => state.reset)
  const stopGame = useGame(state => state.stop)

  useEffect(() => {
    if (location?.pathname === '/') {
      resetGame()
      resetTheme()
      stopGame()
    }
  }, [location])
}
