import { useState, useEffect } from 'react'
import isMobile from 'is-mobile'

export default function useCanvasResizer({ threshold } = { threshold: 60 }) {
  const [size, setSize] = useState(() => ({ width: 0, height: 0 }))

  useEffect(() => {
    if (!isMobile()) return

    function onResize() {
      const { innerWidth: width, innerHeight: height } = window
      const delta = Math.max(size.height, height) - document.documentElement.clientHeight
      if (size.height && (!delta || delta > threshold)) return

      setSize({
        width,
        height,
      })
    }

    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [size])

  if (isMobile()) {
    return size
  } else {
    return { }
  }
}
